import React, { useState, useEffect, useRef} from 'react';
import { Search, ArrowUp, HelpCircle, Share2, ExternalLink, RefreshCw, Menu, User, Heart, ArrowUpToLine } from 'lucide-react';


import { functions, auth, analytics } from './firebase';  // Adjust the path if needed
import { httpsCallable } from 'firebase/functions';
import { signInAnonymously, onAuthStateChanged, signOut } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';

const LikeImFive = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTerm, setCurrentTerm] = useState('');
  const [explanations, setExplanations] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [sparklingIndex, setSparklingIndex] = useState(-1);
  const helpOverlayRef = useRef(null);
  const contentRef = useRef(null);
  const latestExplanationRef = useRef(null);
  const [exampleTopics, setExampleTopics] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const menuOverlayRef = useRef(null);
  const menuButtonRef = useRef(null);
  const [showBackToTop, setShowBackToTop] = useState(false);
  // const [user, setUser] = useState(null);

  const [allParagraphs, setAllParagraphs] = useState([]);
  const [currentParagraphIndex, setCurrentParagraphIndex] = useState(0);


  const [selection, setSelection] = useState(null);
  const [showExplainButton, setShowExplainButton] = useState(false);
  const [explainButtonPosition, setExplainButtonPosition] = useState({ top: 0, left: 0 });
  const explainButtonRef = useRef(null);


  const [currentProgressPhrase, setCurrentProgressPhrase] = useState(['', '', '']);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const loadingTimerRef = useRef(null);

  const [isSearchTooLong, setIsSearchTooLong] = useState(false);

  const inputRef = useRef(null);

  const [illustrations, setIllustrations] = useState([]);
  const [isIllustrationLoading, setIsIllustrationLoading] = useState(false);

  const [relatedTopics, setRelatedTopics] = useState([]);
  const [showRelatedTopics, setShowRelatedTopics] = useState(false);


  const [isClarifying, setIsClarifying] = useState(false);


  const handleSignOut = async () => {
    try {
        await signOut(auth); // Sign out the user
        console.log("User signed out successfully.");
        // No need to manually redirect; the App.js handles the UI based on the user's authentication state
    } catch (error) {
        console.error("Error signing out:", error);
    }
};

  const fetchIllustration = async (paragraphText, index) => {
    setIsIllustrationLoading(true);
    try {
      const getIllustration = httpsCallable(functions, 'getIllustration');
      const result = await getIllustration({ paragraphText });
      setIllustrations(prev => {
        const newIllustrations = [...prev];
        newIllustrations[index] = result.data.imageUrl;
        return newIllustrations;
      });
    } catch (error) {
      console.error('Error fetching illustration:', error);
    } finally {
      setIsIllustrationLoading(false);
    }
  };

  useEffect(() => {
    // Update the document title when the currentTerm changes
    if (currentTerm) {
      document.title = `${currentTerm} - Like I'm Five`;
    } else {
      document.title = `Like I'm Five`;
    }
  }, [currentTerm]);


  useEffect(() => {
    const handleScroll = () => {
      // Show the button when the user has scrolled down 100px from the top
      setShowBackToTop(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };



  useEffect(() => {
    const handleSelection = () => {
      const selection = window.getSelection();
    const selectedText = selection.toString().trim();
    
    // Function to check if a node is within the input field
    const isWithinInput = (node) => {
      return document.activeElement === inputRef.current;
    };

    // Check if either the start or end of the selection is within the input
    const selectionStartInInput = isWithinInput(selection.anchorNode);
    const selectionEndInInput = isWithinInput(selection.focusNode);

    console.log('Selection:', selectedText);
    console.log('selectionStartInInput:', selectionStartInInput);
    console.log('selectionEndInInput:', selectionEndInInput);
  
    if (selectedText && 
      selectedText !== currentTerm && 
      selectedText.length < 130 && 
      !selectionStartInInput &&
      !selectionEndInInput) {
    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    
    setSelection(selectedText);
    setExplainButtonPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + (rect.width / 2)
    });
    setShowExplainButton(true);
  } else {
    setShowExplainButton(false);
  }
};
  
    const handleMouseDown = (e) => {
      if (explainButtonRef.current && !explainButtonRef.current.contains(e.target)) {
        setShowExplainButton(false);
      }
    };
  
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setShowExplainButton(false);
      }
    };
  
    document.addEventListener('selectionchange', handleSelection);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('selectionchange', handleSelection);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentTerm]);

  const handleExplainThis = () => {
    // Encode the selected text to ensure it's URL-safe
    const encodedTerm = encodeURIComponent(selection);
    
    // Construct the URL for the new tab
    // Adjust the URL structure based on your app's routing
    const newTabUrl = `${window.location.origin}?term=${encodedTerm}`;
    
    // Open the new tab
    window.open(newTabUrl, '_blank');
    
    // Hide the "Explain this" button
    setShowExplainButton(false);
  };

  useEffect(() => {
    // Check if there's a term in the URL and autosubmit is true
    const urlParams = new URLSearchParams(window.location.search);
    const termFromUrl = urlParams.get('term');
    
    if (termFromUrl) {
      setSearchTerm(termFromUrl);
      performSearch(termFromUrl);
    }
  }, []); // Empty dependency array ensures this runs only once on component mount





  useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuOverlayRef.current && !menuOverlayRef.current.contains(event.target) &&
            menuButtonRef.current && !menuButtonRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);


const handleMenuToggle = () => {
  setShowMenu(prevState => !prevState);
};





  const topics = [
    "Cryptocurrency", "Artificial Intelligence", "Climate Change", "Quantum Computing",
    "Blockchain", "5G Technology", "Gene Editing", "Renewable Energy",
    "Internet of Things", "Big Data", "Machine Learning", "Virtual Reality",
    "Augmented Reality", "Cybersecurity", "Cloud Computing", "3D Printing",
    "Autonomous Vehicles", "Drone Technology", "Space Exploration", "Nanotechnology",
    "Biotechnology", "Robotics", "Sustainable Agriculture", "Smart Cities",
    "Digital Privacy", "Artificial Neural Networks", "Biometrics", "Edge Computing",
    "Telemedicine", "Wearable Technology", "Green Energy", "Fusion Power",
    "Quantum Cryptography", "Bioinformatics", "Synthetic Biology", "Precision Medicine",
    "Internet Privacy", "Data Mining", "Natural Language Processing", "Computer Vision",
    "Augmented Intelligence", "Predictive Analytics", "Chatbots", "Digital Twins",
    "Gesture Recognition", "Haptic Technology", "Brain-Computer Interfaces", "Quantum Sensors",
    "Neuromorphic Computing", "Exoskeletons", "Smart Grids", "Carbon Capture",
    "Vertical Farming", "Lab-grown Meat", "Bioplastics", "Graphene Applications",
    "CRISPR Technology", "Stem Cell Research", "Nanomedicine", "Optogenetics",
    "Photonics", "Metamaterials", "Superconductivity", "Hyperloop Transportation",
    "Smart Fabrics", "Biometric Authentication", "Quantum Dots", "Holographic Displays",
    "Swarm Robotics", "Programmable Matter", "Molecular Machines", "Neuroplasticity",
    "Personalized Nutrition", "Digital Currencies", "Synthetic Aperture Radar", "Plasma Propulsion",
    "Quantum Machine Learning", "Biohacking", "Organ-on-a-Chip", "Neuromorphic Engineering",
    "Adaptive Learning Systems", "Fog Computing", "Precision Agriculture", "Smart Dust",
    "Volumetric Displays", "Quantum Teleportation", "Metamemory", "Biomimicry",
    "Cognitive Computing", "Molecular Nanotechnology", "Organoids", "Photovoltaics",
    "Thermoelectric Materials", "Bioprinting", "Neurofeedback", "Soft Robotics",
    "Computational Linguistics", "Blockchain Voting", "Bioremediation", "Hyperspectral Imaging"
];


useEffect(() => {
  setExampleTopics(getRandomTopics(3));
}, []);

const getRandomTopics = (count) => {
  const shuffled = [...topics].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};


  const generateRandomExplanation = (term) => {
    const paragraphs = [
      `${term} is like a magical ${['potion', 'spell', 'wand', 'creature'][Math.floor(Math.random() * 4)]} in the world of ${['wizards', 'fairies', 'dragons', 'unicorns'][Math.floor(Math.random() * 4)]}.`,
      `Imagine you're in a ${['castle', 'forest', 'underwater city', 'space station'][Math.floor(Math.random() * 4)]}, and ${term} is the key to ${['unlock a secret door', 'befriend a magical creature', 'solve a mysterious puzzle', 'power a fantastic machine'][Math.floor(Math.random() * 4)]}.`,
      `Just like how ${['a chef uses recipes', 'an artist uses colors', 'a musician uses notes', 'a builder uses blocks'][Math.floor(Math.random() * 4)]}, people use ${term} to ${['create amazing things', 'solve big problems', 'explore new worlds', 'understand the universe'][Math.floor(Math.random() * 4)]}.`,
      `Every time someone uses ${term}, it's like ${['planting a seed', 'lighting a candle', 'solving a riddle', 'discovering a treasure'][Math.floor(Math.random() * 4)]} that can grow into something ${['beautiful', 'powerful', 'mysterious', 'revolutionary'][Math.floor(Math.random() * 4)]}.`,
      `If ${term} were a superhero, its superpower would be ${['flying', 'invisibility', 'super strength', 'time travel'][Math.floor(Math.random() * 4)]}, helping people to ${['save the day', 'learn new things', 'make friends', 'build a better world'][Math.floor(Math.random() * 4)]}.`
    ];

    // Randomly select 3-5 paragraphs
    const numParagraphs = Math.floor(Math.random() * 3) + 3;
    const shuffled = paragraphs.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, numParagraphs);
  };


  const performSearch = async (term) => {
    const trimmedTerm = term.trim().toLowerCase();
    if (trimmedTerm) {
      setCurrentTerm(trimmedTerm);
      setIsLoading(true);
      setExplanations([]);
      setShowButtons(false);
      setAllParagraphs([]);
      setCurrentParagraphIndex(0);
      setIllustrations([]);
      setShowRelatedTopics(false); // Hide related topics when starting a new search

      startProgressPhraseDisplay();

      try {
        const getExplanation = httpsCallable(functions, 'getExplanation');
        const result = await getExplanation({ searchTerm: trimmedTerm });
        
        const paragraphs = result.data.split('###').map(p => p.trim()).filter(p => p);
        setAllParagraphs(paragraphs);
        setExplanations([paragraphs[0]]);

        // Stop loading and hide progress phrases
        setIsLoading(false);
        stopProgressPhraseDisplay();

        // Start fetching the first illustration
        fetchIllustration(paragraphs[0], 0);

        // console.log(`Received explanation for term: "${trimmedTerm}"`);
        // console.log(`Number of paragraphs: ${paragraphs.length}`);
        // console.log(`First paragraph: ${paragraphs[0].substring(0, 50)}...`);
        
      } catch (error) {
        console.error('Error calling Firebase function:', error);
        setIsLoading(false);
        stopProgressPhraseDisplay();
        // Handle the error appropriately (e.g., show an error message to the user)
      } finally {
        setShowButtons(true);
        scrollToNewExplanation();
      }
    } else {
      setCurrentTerm('');
      setExplanations([]);
      setShowRelatedTopics(false); // Hide related topics when search term is empty

    }
  };


  const startProgressPhraseDisplay = () => {
    const displayNextPhrase = () => {
      const randomIndex = Math.floor(Math.random() * progressPhrases.length);
      setCurrentProgressPhrase(progressPhrases[randomIndex]);
      setCurrentPhraseIndex(0);

      loadingTimerRef.current = setTimeout(() => {
        setCurrentPhraseIndex(1);
        loadingTimerRef.current = setTimeout(() => {
          setCurrentPhraseIndex(2);
          loadingTimerRef.current = setTimeout(() => {
            setCurrentPhraseIndex(-1); // No text shown
            loadingTimerRef.current = setTimeout(displayNextPhrase, 2500);
          }, 3500);
        }, 3500);
      }, 3000);
    };

    displayNextPhrase();
  };

  const stopProgressPhraseDisplay = () => {
    if (loadingTimerRef.current) {
      clearTimeout(loadingTimerRef.current);
    }
    setCurrentProgressPhrase(['', '', '']);
    setCurrentPhraseIndex(-1);
  };

  useEffect(() => {
    return () => {
      if (loadingTimerRef.current) {
        clearTimeout(loadingTimerRef.current);
      }
    };
  }, []);



  const scrollToNewExplanation = () => {
    setTimeout(() => {
      if (latestExplanationRef.current) {
        const yOffset = -50; // Adjust this value to fine-tune the scroll position
        const y = latestExplanationRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 100);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!isSearchTooLong) {
      performSearch(searchTerm);
    }
  };

  const updateSearchTermAndLength = (text) => {
    setSearchTerm(text);
    setIsSearchTooLong(text.length > 130);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    updateSearchTermAndLength(inputValue);
    if (inputValue === '') {
      setExplanations([]);
      setCurrentTerm('');
      setShowRelatedTopics(false); // Hide related topics when search term is deleted

    }
  };
  
  const handleExampleClick = (example) => {
    updateSearchTermAndLength(example);
    performSearch(example);
  };


  const handleTellMeMore = async () => {
    setIsLoading(true);
    setShowButtons(false);
    
    const nextIndex = currentParagraphIndex + 1;
    if (nextIndex < allParagraphs.length) {
      const illustrationPromise = fetchIllustration(allParagraphs[nextIndex], nextIndex);

      
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      setExplanations(prev => [...prev, allParagraphs[nextIndex]]);
      setCurrentParagraphIndex(nextIndex);

      setIsLoading(false);
      setShowButtons(nextIndex < allParagraphs.length - 1);
      scrollToNewExplanation();

      // If this is the last paragraph, fetch related topics
      if (nextIndex === allParagraphs.length - 1) {
        fetchRelatedTopics();
      }
      
      await illustrationPromise;
      

    } else {
      setIsLoading(false);
    }
  };


  const fetchRelatedTopics = async () => {
    try {
      const getRelatedTopics = httpsCallable(functions, 'getRelatedTopics');
      const result = await getRelatedTopics({ currentTopic: currentTerm });
      const topics = result.data.split(',').map(topic => topic.trim());
      setRelatedTopics(topics);
      setShowRelatedTopics(true);
    } catch (error) {
      console.error('Error fetching related topics:', error);
    }
  };


  const handleRelatedTopicClick = (topic) => {
    const encodedTerm = encodeURIComponent(topic);
    const newTabUrl = `${window.location.origin}?term=${encodedTerm}`;
    window.open(newTabUrl, '_blank');
  };



  // const handleTryAgain = async () => {
  //   setIsLoading(true);
  //   setShowButtons(false);
  //   setSparklingIndex(explanations.length - 1);
    
  //   scrollToNewExplanation();
    
  //   // Simulate API call
  //   await new Promise(resolve => setTimeout(resolve, 3000));
    
  //   const newExplanation = generateRandomExplanation(currentTerm);
  //   setExplanations(prevExplanations => {
  //     const updatedExplanations = [...prevExplanations];
  //     updatedExplanations[updatedExplanations.length - 1] = newExplanation;
  //     return updatedExplanations;
  //   });
  //   setIsLoading(false);
  //   setShowButtons(true);
  //   setSparklingIndex(-1);
  // };

  const handleTryAgain = async () => {
    setIsLoading(true);
    setShowButtons(false);
    setSparklingIndex(explanations.length - 1);
    
    scrollToNewExplanation();
    
    try {
      const clarifyParagraph = httpsCallable(functions, 'clarifyParagraph');
      const result = await clarifyParagraph({ paragraph: explanations[explanations.length - 1] });
      
      setExplanations(prevExplanations => {
        const updatedExplanations = [...prevExplanations];
        updatedExplanations[updatedExplanations.length - 1] = result.data;
        return updatedExplanations;
      });
    } catch (error) {
      console.error('Error calling clarifyParagraph function:', error);
      // Optionally, show an error message to the user
    } finally {
      setIsLoading(false);
      setShowButtons(true);
      setSparklingIndex(-1);
    }
  };


  const handleRestartOld = () => {
    setIsLoading(true);
    setExplanations([]);
    setShowButtons(false);
    
    // Simulate API call
    setTimeout(() => {
      setIsLoading(false);
      setExplanations([generateRandomExplanation(currentTerm)]);
      setShowButtons(true);
      scrollToNewExplanation();
    }, 3000);
  };

  const handleRestart = () => {
    setSearchTerm('');
    setCurrentTerm('');
    setExplanations([]);
    setIsLoading(false);
    setShowButtons(true);
    setExampleTopics(getRandomTopics(3));  // Add this line to get new random topics
    setShowRelatedTopics(false); // Hide related topics when restarting

    // Reset any other state variables as needed
  };



  const examples = ['Inflation', 'Options', 'Shorting', 'Immigration'];

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4 relative">
      <h1 className="text-4xl font-bold mb-2">Like I'm Five</h1>
      <p className="text-xl mb-8">Learn like a kid. Know like a pro.</p>

      <form onSubmit={handleSearch} className="w-full max-w-lg mb-4 relative">
  {isSearchTooLong && (
    <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 flex items-center">
      <svg className="w-4 h-4 text-yellow-500 mr-1" fill="currentColor" viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
      </svg>
      <span className="text-xs text-yellow-600">Shorten your search please.</span>
    </div>
  )}
  <div className="relative">
    <input
      ref={inputRef}
      type="text"
      value={searchTerm}
      onChange={handleInputChange}
      placeholder="What would you like to know?"
      className="w-full p-4 pr-12 text-gray-900 border-none rounded-full bg-white shadow-[0_0_10px_rgba(0,0,0,0.1)] focus:outline-none focus:ring-2 focus:ring-blue-300"
    />
    <button
      type="submit"
      className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-500"
    >
      {searchTerm ? <ArrowUp className="h-5 w-5" /> : <Search className="h-5 w-5" />}
    </button>
  </div>
</form>

      {explanations.length === 0 && !isLoading && (
                <div className="flex flex-wrap justify-center gap-2 mb-8">
                    <span className="text-gray-500">For example:</span>
                    {exampleTopics.map((example, index) => (
                        <button
                            key={index}
                            onClick={() => handleExampleClick(example)}
                            className="px-3 py-1 bg-gray-100 text-gray-800 rounded-full text-sm"
                        >
                            {example}
                        </button>
                    ))}
                </div>
            )}



<div ref={contentRef} className="w-full max-w-lg">
        {(explanations.length > 0 || isLoading) && (
          <h2 className="text-2xl font-bold mb-4 text-center">{currentTerm}</h2>
        )}
      {explanations.map((explanation, index) => (
          <SparklingExplanation
            key={index}
            explanation={[explanation]}
            isEven={index % 2 === 0}
            isSparkling={index === sparklingIndex}
            index={index}
            illustrations={illustrations}
            ref={index === explanations.length - 1 ? latestExplanationRef : null}
          />
        ))}
        {isLoading && (
        <div className="w-full flex flex-col justify-center items-center mt-4">
          <img src="/images/magic-wand.gif" alt="Loading..." className="w-16 h-16" />
          {currentPhraseIndex >= 0 && currentPhraseIndex < 3 && (
            <p className="text-lg text-gray-600 mt-2 font-medium">
              {currentProgressPhrase[currentPhraseIndex]}
            </p>
          )}
        </div>
      )}
        {showButtons && explanations.length > 0 && currentParagraphIndex < allParagraphs.length - 1 && (
          <FadeInElement>
            <div className="buttons-container flex justify-center sm:justify-between mt-8 gap-4">
              <button 
                onClick={handleTryAgain}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg"
              >
                Clarify
              </button>
              <button 
                onClick={handleTellMeMore}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                Tell me more
              </button>
              <button 
                onClick={handleRestart}
                className="px-4 py-2 bg-green-500 text-white rounded-lg flex items-center"
              >
                <RefreshCw className="mr-2 h-4 w-4" /> Restart
              </button>
            </div>
          </FadeInElement>
        )}

{showRelatedTopics && (
          <FadeInElement>
            <div className="mt-8 text-center">
              <h3 className="text-lg text-gray-600 mb-2">Related topics:</h3>
              <div className="flex flex-wrap justify-center gap-2">
                {relatedTopics.map((topic, index) => (
                  <button
                    key={index}
                    onClick={() => handleRelatedTopicClick(topic)}
                    className="px-3 py-1 bg-gray-100 text-gray-800 rounded-full text-sm"
                  >
                    {topic}
                  </button>
                ))}
              </div>
            </div>
          </FadeInElement>
        )}

      </div>




 {/* Bottom buffer */}
 <div className="h-64"></div>

 {/* {explanations.length > 0 && (
        <div 
          className="fixed top-4 right-8 z-40"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <button
            // Remove onClick handler for now
            className="bg-white rounded-full p-2 shadow-md share-button"
          >
            <Share2 className="h-6 w-6 text-gray-600" />
          </button>
          {showTooltip && (
            <div className="absolute right-0 top-full mt-2 px-3 py-1 bg-black text-white text-xs rounded whitespace-nowrap">
              Share
            </div>
          )}
        </div>
      )} */}

{explanations.length > 0 && showBackToTop && (
        <button 
          onClick={scrollToTop}
          className="fixed bottom-4 left-4 z-40 bg-white rounded-full p-2 shadow-md back-to-top-button"
          aria-label="Back to top"
        >
          <ArrowUpToLine className="h-6 w-6 text-gray-600" />
        </button>
      )}

            <div className="fixed bottom-4 right-4 z-40">
                <button 
                    ref={menuButtonRef}
                    onClick={handleMenuToggle} 
                    className="bg-white rounded-full p-2 shadow-md menu-button"
                >
                    <Menu className="h-6 w-6 text-gray-600" />
                </button>
            </div>


            {showMenu && (
                <div 
                    ref={menuOverlayRef}
                    className="fixed bottom-16 right-4 bg-white p-6 rounded-lg shadow-lg border border-gray-200 max-h-[80vh] overflow-y-auto menu-overlay"
                >
                    <ul className="space-y-4">
                        {/* <li>
                            <a href="#" className="text-gray-600 hover:text-gray-800 flex items-center p-2 rounded hover:bg-gray-100">
                                <User size={18} className="mr-3" />
                                <span className="text-base">Account</span>
                            </a>
                        </li> */}
                        {/* <li>
                            <a href="#" className="text-gray-600 hover:text-gray-800 flex items-center p-2 rounded hover:bg-gray-100">
                                <Heart size={18} className="mr-3" />
                                <span className="text-base">About</span>
                            </a>
                        </li> */}
                        {/* <li>
                            <a href="#" className="text-gray-600 hover:text-gray-800 flex items-center p-2 rounded hover:bg-gray-100">
                                <HelpCircle size={18} className="mr-3" />
                                <span className="text-base">Help & FAQ</span>
                            </a>
                        </li> */}
                        <li>
                            <button 
                                onClick={handleSignOut}  // Attach the sign-out functionality
                                className="text-gray-600 hover:text-gray-800 flex items-center p-2 rounded hover:bg-gray-100 w-full text-left"
                            >
                                <User size={18} className="mr-3" /> {/* User profile icon */}
                                <span className="text-base">Sign Out</span>
                            </button>
                        </li>
                        <li>
                          <a href="/termsofservice.html" target="_blank" className="text-gray-600 hover:text-gray-800 flex items-center p-2 rounded hover:bg-gray-100">
                            <ExternalLink size={18} className="mr-3" />
                            <span className="text-base">Terms of Service</span>
                          </a>
                        </li>
                        <li>
                          <a href="/privacypolicy.html" target="_blank" className="text-gray-600 hover:text-gray-800 flex items-center p-2 rounded hover:bg-gray-100">
                            <ExternalLink size={18} className="mr-3" />
                            <span className="text-base">Privacy Policy</span>
                          </a>
                        </li>
                        {/* <li>
                            <a href="#" className="text-gray-600 hover:text-gray-800 flex items-center p-2 rounded hover:bg-gray-100">
                                <ExternalLink size={18} className="mr-3" />
                                <span className="text-base">Feedback</span>
                            </a>
                        </li> */}
                    </ul>
                </div>
            )}


            {/* Explain This button */}
            {showExplainButton && (
          <button
            ref={explainButtonRef}
            onClick={handleExplainThis}
            className="text-[15px] absolute bg-white text-gray-800 px-3 py-1 rounded-lg shadow-md border border-gray-300 text-sm"
            style={{
              top: `${explainButtonPosition.top}px`,
              left: `${explainButtonPosition.left}px`,
              transform: 'translate(-50%, 18px)',
              zIndex: 1000,
            }}
          >
            Explain this
          </button>
        )}
    </div>
  );
};


const SparklingExplanation = React.forwardRef(({ explanation, isEven, isSparkling, index, illustrations }, ref) => {
  return (
    <div 
      ref={ref}
      className={`mb-8 explanation relative ${isSparkling ? 'sparkle-container' : ''} ${isEven ? 'flex-row' : 'flex-row-reverse'}`}
    >
      <div className="illustration">
        <FadeInImage 
          src={illustrations[index]}
          alt="Illustration" 
          className="w-full h-full"
        />
      </div>
      <div className="text-content">
        {explanation.map((paragraph, pIndex) => (
          <FadeInElement key={pIndex}>
            <p className="mb-4 text-center sm:text-left">{paragraph}</p>
          </FadeInElement>
        ))}
      </div>
      {isSparkling && 
        <SparkleEffect 
          count={30} 
          topStart={0} 
          topEnd={100} 
          leftStart={0} 
          leftEnd={100} 
        />
      }
    </div>
  );
});

const SparklingPlaceholder = () => {
  return (
    <div className="sparkling-placeholder">
      <SparkleEffect 
        count={10} 
        topStart={30} 
        topEnd={70} 
        leftStart={30} 
        leftEnd={70} 
      />
    </div>
  );
};

const SparkleEffect = ({ 
  count = 10, 
  topStart = 25, 
  topEnd = 75, 
  leftStart = 25, 
  leftEnd = 75 
}) => {
  return (
    <div className="sparkles">
      {[...Array(count)].map((_, i) => (
        <div key={i} className="sparkle" style={{
          '--size': `${Math.random() * 14 + 10}px`,
          '--top': `${topStart + Math.random() * (topEnd - topStart)}%`,
          '--left': `${leftStart + Math.random() * (leftEnd - leftStart)}%`,
          '--animation-delay': `${Math.random() * 2}s`
        }}></div>
      ))}
    </div>
  );
};


const FadeInImage = ({ src, alt, className }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [showEnlarged, setShowEnlarged] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const imageRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 500);
    return () => clearTimeout(timer);
  }, []);

  const handleClick = () => {
    if (imageRef.current) {
      const { width, height } = imageRef.current.getBoundingClientRect();
      setImageDimensions({ width, height });
    }
    setShowEnlarged(true);
  };

  return (
    <>
      <div 
        className={`${className} transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
        onClick={handleClick}
      >
        {src ? (
          <img 
            ref={imageRef}
            src={src} 
            alt={alt} 
          />
        ) : (
          <SparklingPlaceholder />
        )}
      </div>
      {showEnlarged && (
        <EnlargedImageOverlay
          src={src}
          alt={alt}
          onClose={() => setShowEnlarged(false)}
          dimensions={imageDimensions}
        />
      )}
    </>
  );
};


const FadeInElement = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
      const timer = setTimeout(() => setIsVisible(true), 500);
      return () => clearTimeout(timer);
  }, []);

  return (
      <div className={`transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
          {children}
      </div>
  );
};

const EnlargedImageOverlay = ({ src, alt, onClose, dimensions }) => {
  const [orientation, setOrientation] = useState(window.innerHeight > window.innerWidth ? 'portrait' : 'landscape');
  const enlargementFactor = 3;

  useEffect(() => {
      const handleResize = () => {
          setOrientation(window.innerHeight > window.innerWidth ? 'portrait' : 'landscape');
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleOutsideClick = (e) => {
      if (e.target === e.currentTarget) {
          onClose();
      }
  };

  const getImageStyle = () => {
      if (orientation === 'portrait') {
          return {
              width: '90vw',
              height: 'auto',
              maxHeight: '90vh',
          };
      } else {
          return {
              width: `${dimensions.width * enlargementFactor}px`,
              height: `${dimensions.height * enlargementFactor}px`,
              maxWidth: '90vw',
              maxHeight: '90vh',
          };
      }
  };

  return (
      <div 
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" 
          onClick={handleOutsideClick}
      >
          <div style={getImageStyle()}>
              <img 
                  src={src} 
                  alt={alt} 
                  className="w-full h-full object-contain"
              />
          </div>
      </div>
  );
};


const progressPhrases = [
  ["The explain-o-bots are scampering", "They're searching high and low", "They've found something!"],
  ["The explain-o-bots are on the job!", "They're crunching the numbers", "The answer is..."],
  ["The explain-o-bots are on the move", "They're leaving no stone unturned", "They've got it!"],
  ["Your order has been received", "Explain-o-bots are cooking", "Out for delivery!"],
  ["Explain-o-bots are in the workshop", "They're hand-crafting a response", "And Voilà!"],
  ["Explain-o-bots are in the mines", "Searching for a diamond in the rough", "They've found something!"],
  ["Explain-o-bots are in the orchard", "Plucking the answers for you", "Here they come..."],
  ["Explain-o-bots are at the space station", "They're beaming down an answer", "beep boop beep boop"],
  ["Explain-o-bots are on a quest", "They're battling confusion dragons", "Victory is near!"],
  ["Explain-o-bots are diving deep", "They're exploring the unknown", "They've surfaced with answers!"],
  ["Explain-o-bots are in the library", "They're flipping through the pages", "Eureka!"],
  ["Explain-o-bots are in the lab", "Mixing up the perfect solution", "And it's ready!"],
  ["Explain-o-bots are in the forest", "They're foraging for facts", "They've found the truth!"],
  ["Explain-o-bots are on the trail", "They're tracking down the details", "They've made a find!"],
  ["Explain-o-bots are in the archive", "Dusting off the old books", "They've uncovered the answer!"],
  ["Explain-o-bots are on the mountain", "Climbing to new heights", "They've reached the peak!"],
  ["Explain-o-bots are in the bakery", "Whipping up something special", "Here's the treat!"],
  ["Explain-o-bots are in the city", "Navigating the maze of knowledge", "They've arrived!"],
  ["Explain-o-bots are in the factory", "Building the response", "It's ready for delivery!"],
  ["Explain-o-bots are in the desert", "Searching through the sands", "They've struck gold!"],
  ["Explain-o-bots are at the beach", "Sifting through the sand", "They've found a pearl!"],
  ["Explain-o-bots are in the jungle", "Cutting through the vines", "They've made a discovery!"],
  ["Explain-o-bots are in the ice", "Chiseling out the truth", "It's thawed out!"],
  ["Explain-o-bots are time-traveling", "They're gathering wisdom from the ages", "Back to the present!"],
  ["Explain-o-bots are in the garden", "They're cultivating fresh ideas", "The knowledge is blooming!"],
  ["Explain-o-bots are on a treasure hunt", "They're following the knowledge map", "X marks the spot!"],
  ["Explain-o-bots are on stage", "They're rehearsing your answer", "Curtain's going up!"],
  ["Explain-o-bots are painting", "They're creating a masterpiece", "The canvas is complete!"],
  ["Explain-o-bots are composing", "They're writing a knowledge symphony", "The finale is near!"],
  ["Explain-o-bots are stargazing", "They're decoding celestial wisdom", "Shooting star incoming!"],
  ["Explain-o-bots are on a safari", "They're tracking elusive facts", "They've spotted something!"],
  ["Explain-o-bots are weaving", "They're creating a tapestry of knowledge", "The pattern is clear!"],
  ["Explain-o-bots are solving the puzzle", "They're piecing it all together", "The picture is clear!"],
  ["Explain-o-bots are on a road trip", "They're navigating the info highway", "Destination reached!"]
];



export default LikeImFive;