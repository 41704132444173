import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDtiIjlg8Hhb1blbtXqaKsVZl3t4IQs0HI",
  authDomain: "likeimfive.ai",
  projectId: "likeimfivedotai",
  storageBucket: "likeimfivedotai.appspot.com",
  messagingSenderId: "339551892261",
  appId: "1:339551892261:web:1e1db12e2a08a6f5c960cc",
  measurementId: "G-YZHXM98PRL"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    // You can handle the signed-in user here if needed
    return result.user;
  } catch (error) {
    console.error("Error signing in with Google", error);
    throw error;
  }
};

export { app, analytics, functions, auth };