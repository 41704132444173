import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './components/firebase';
import LikeImFive from './components/LikeImFive';
import LandingPage from './components/LandingPage';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Check if the user is signed in anonymously
        if (user.isAnonymous) {
          // Sign out the user if they are anonymous
          signOut(auth).then(() => {
            // Redirect to the landing page or simply set user to null
            setUser(null);
          });
        } else {
          setUser(user);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
        // <div className="w-full h-screen flex flex-col justify-center items-center">
        //     <img src="/images/magic-wand.gif" alt="Loading..." className="w-16 h-16" />
        // </div>
        <div></div>
    );
}

  return (
    <div className="App">
      {user ? <LikeImFive /> : <LandingPage />}
    </div>
  );
}

export default App;