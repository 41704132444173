import React, { useState, useEffect } from 'react';
import { signInWithGoogle } from './firebase';

const examples = ['DNA', 'Inflation', 'Quantum Computing', 'Black Holes', 'Blockchain'];

const AnimatedInput = () => {
  const [displayText, setDisplayText] = useState('');
  const [currentExample, setCurrentExample] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const typeText = async () => {
      if (isTyping) {
        for (let i = 0; i <= examples[currentExample].length; i++) {
          setDisplayText(examples[currentExample].slice(0, i));
          await new Promise(resolve => setTimeout(resolve, 100));
        }
        await new Promise(resolve => setTimeout(resolve, Math.random() * 1000));
        setIsTyping(false);
      } else {
        for (let i = examples[currentExample].length; i >= 0; i--) {
          setDisplayText(examples[currentExample].slice(0, i));
          await new Promise(resolve => setTimeout(resolve, 50));
        }
        await new Promise(resolve => setTimeout(resolve, Math.random() * 1000));
        setCurrentExample((prev) => (prev + 1) % examples.length);
        setIsTyping(true);
      }
    };
    typeText();
  }, [currentExample, isTyping]);

  return (
    <div className="relative w-full max-w-lg">
      <input
        type="text"
        value={displayText}
        readOnly
        className="w-full p-4 pr-12 text-gray-900 border-none rounded-full bg-white shadow-[0_0_10px_rgba(0,0,0,0.1)] focus:outline-none focus:ring-2 focus:ring-blue-300"
        placeholder="What would you like to know?"
      />
      <div className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-500">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </div>
    </div>
  );
};

const LandingPage = () => {

    const handleSignIn = async () => {
        try {
          const user = await signInWithGoogle();
          console.log("Signed in user:", user);
          // Handle successful sign-in (e.g., redirect to dashboard)
        } catch (error) {
          console.error("Sign-in error:", error);
          // Handle sign-in error (e.g., show error message)
        }
      };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
      <h1 className="text-4xl font-bold mb-2">Like I'm Five</h1>
      <p className="text-xl mb-8">Learn like a kid. Know like a pro.</p>

      <AnimatedInput />

      <div className="mt-8 text-center max-w-md"> {/* Added max-w-md to limit text width */}
        <p className="mb-2">Our Explain-o-bots are raring to answer any question under the sun! But they're a little wary of strangers.</p>
        <br></br>
        <p className="mb-6">Sign in to continue.</p>
        <br></br>
        <div className="flex justify-center"> {/* Added flex container to center the button */}
          <button
            onClick={handleSignIn}
            className="bg-blue-600 text-white px-6 py-3 rounded-full text-base font-medium hover:bg-blue-700 transition duration-300 flex items-center justify-center shadow-md"
          >
            <svg className="w-5 h-5 mr-3" viewBox="0 0 24 24">
              <path
                fill="#ffffff"
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              />
              <path
                fill="#ffffff"
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              />
              <path
                fill="#ffffff"
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              />
              <path
                fill="#ffffff"
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              />
              <path fill="none" d="M1 1h22v22H1z" />
            </svg>
            Sign in with Google
          </button>
        </div>

        <p className="mt-4 text-sm text-gray-400">
          If you already have an account we'll log you in.
        </p>
      </div>
    </div>
  );
};

export default LandingPage;